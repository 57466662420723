import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class SecurityService {

  constructor(private router:Router) { }

  processAnswer(data){
    data.subscribe( data => {
      if(data.message == "Not a valid API request."){
        this.logoutHandler();
      }
    },
    error =>{
      //this.logoutHandler();
    });
  }

  processAnswerPipe(data){
    if(data.message == "Not a valid API request."){
      this.logoutHandler();
    }
  }

  logoutHandler(){
    let lang=localStorage.getItem('lang');
    localStorage.clear();
    localStorage.setItem('lang', lang);
    this.router.navigate( ['/login'] );
    location.reload();
  }
}
