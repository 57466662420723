import { Component, OnInit } from '@angular/core';
import { RecommendedService } from './../../services/recommended.service';
declare var $: any;

@Component({
  selector: 'app-recommended-history',
  templateUrl: './recommended-history.component.html'
})
export class RecommendedHistoryComponent implements OnInit {
  stories:any=[];
  currentRec:any='';
  currentRecom: any = {
    partner_id: '',
    id: '',
  };

  constructor(
    private _recommendedService: RecommendedService) {
      this.currentRec = localStorage.getItem('currentRecommended');
      this.getPreRequestHistory(this.currentRec);
      $('#loader').show();
 }

  ngOnInit() {
    //
  }

  getPreRequestHistory(idRecommended) {
    let partnerId:string = localStorage.getItem('partner_id');
    this.currentRecom.partner_id = partnerId;
    this.currentRecom.id = idRecommended;
    this._recommendedService.getPreRequestHistory(this.currentRecom)
    .subscribe( data => {
      if(data.status){
        this.stories = data.data;
        setTimeout(() => {
          $('#loader').hide();
        }, 500);
        
      }

    },
    error => console.error(error));
      setTimeout(() => {
        $('#loader').hide();
      }, 500);
      

  }

}
