import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { ModalService } from './../../services/modal-service.service';
import { referredService } from './../../services/referred.service';
import { FormGroup, FormControl, Validators } from '@angular/forms'
declare var $: any;

@Component({
  selector: 'app-new-referral',
  templateUrl: './new-referral.component.html'
})
export class NewReferralComponent implements OnInit {

  referred: any = {
    customer_id      : null,
    partner_id       : '',
    partner_code     : '',
    company_id       : environment.company_id,
    doc_type_id      : '1',
    doc_num          : '',
    birth_date       : '',
    doc_exp_date     : '',
    name             : '',
    lastname         : '',
    email            : '',
    cellphone        : '',
    nickname_id      : '',
    nickname         : '',
    request_status_id: null,
    black_list       : null,
    disabled         : false
  };

  data_policy: any = {
    new_customer: true,
    customer_id : null,
    partner_id  : '',
    doc_num     : '',
    name        : '',
    cellphone   : '',
    smsCode     : null
  };

  customer: any = {
    partner_id: '',
    doc_num   : ''
  };

  preRequest:any=[];

  forma:FormGroup;


  constructor(
    private _referredService: referredService,
    private router: Router,
    private _ModalService: ModalService) {
  }

  ngOnInit() {
    this.forma = new FormGroup({
      'doc_type_id': new FormControl(''),
      'doc_num': new FormControl('', [
        Validators.required,
        Validators.min(10000),
        Validators.max(10000000000),
      ]),
      'doc_exp_date': new FormControl(''),
      'name': new FormControl('',  [
        Validators.required
      ]),
      'lastname': new FormControl('',[
        Validators.required
      ]),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")
      ]),
      'cellphone': new FormControl('', [
        Validators.required,
        Validators.min(1000000000),
        Validators.max(10000000000),
      ]),
      'nickname': new FormControl(''),
      'company_id': new FormControl(''),
    });
  }

  newReferred() {
    $('#loader').show();
    if(this.referred.cellphone.length != 10){
      this.referred.cellphone = "";
      this._ModalService.openModal('Información', 'Su numero de celular debe contener 10 dígitos ', 'check', 'Aceptar');
      $('#loader').hide();
      return false;
    }
    // validation digit repeat
    if(this.referred.doc_num != ""){
      let number  = this.referred.doc_num;
      let num_doc = new String(this.referred.doc_num);
      let doc     = num_doc.length - 1;
      var regExp  = new RegExp("^(?!.*?([0123456789])\\1{"+doc+"}).+");
       // --- true = some different, false = repeat
      if (regExp.test( number ) == false) {
        this._ModalService.openModal('Información', 'Los dígitos de la Identificación no deben ser iguales' , 'check', 'Aceptar');
        $('#loader').hide();
        return false;
      }
    }

    let partnerId:string = localStorage.getItem('partner_id');
    let partnerCode:string = localStorage.getItem('code');
    this.referred.partner_id = partnerId;
    this.referred.partner_code = partnerCode;
    this._referredService.createRecommended(this.referred)
    .subscribe( data => {
      $('#loader').hide();
      if(data.status){
        this.preRequest = data.data;
        localStorage.setItem('customer_id', String(this.preRequest.customer.id));
        localStorage.setItem('nickname_id', String(this.preRequest.nickname_id));
        this._ModalService.openModal('Información', data.message, 'check', 'Aceptar');
        this.router.navigate(['/secure/simulator']);
      } else {
        this._ModalService.openModal('Información',  data.message, 'info', 'Aceptar');
      }
    },
    error =>{
      console.error(error);
      $('#loader').hide();
    });

  }

  sendMessageDataPolicy(){
    this.data_policy.partner_id  = this.customer.partner_id;
    this.data_policy.customer_id = this.customer.customer_id;
    this.data_policy.doc_num     = this.customer.doc_num;
    this.data_policy.name        = this.referred.name;
    this.data_policy.cellphone   = this.referred.cellphone;
    this._referredService.sendMessageDataPolicy(this.data_policy)
    .subscribe( data => {
      $('#loader').hide();
      if(data.status){
        $('.modal').modal();
        $('#modalVerifyDataPolicy').modal('open');
      } else {
        this._ModalService.openModal('Información', data.message, 'close', 'Aceptar');
      }
    },
    error =>{
      console.error(error);
      $('#loader').hide();
    });
  }

  verifyMessageDataPolicy(){
    this.data_policy.smsCode = this.data_policy.smsCode;
    this._referredService.verifyMessageDataPolicy(this.data_policy)
    .subscribe( data => {
      $('#loader').hide();
      if(data.status){
        this.data_policy.new_customer = false;
        this._ModalService.openModal('Información', data.message, 'check', 'Continuar');
        this.newReferred();
      } else {
        this._ModalService.openModal('Información',  data.message, 'info', 'Aceptar');
      }
    },
    error =>{
      console.error(error);
      $('#loader').hide();
    });
  }

  bntValidateNewCustomer() {
    if ( this.data_policy.new_customer ) {
      $('#loader').show();
      this.sendMessageDataPolicy();
    } else {
      this.newReferred();
    }
  }

  getPartnerCustomer(){
    $('#loader').show();

    if(this.referred.doc_num == "" || this.referred.doc_num == null){
      this._ModalService.openModal('Información', 'Ingrese un numero de documento valido', 'check', 'Aceptar');
      $('#loader').hide();
      return false;
    }

    let partnerId:string = localStorage.getItem('partner_id');
    this.customer.partner_id = this.data_policy.partner_id = partnerId;
    this.customer.doc_num = this.data_policy.doc_num = this.referred.doc_num;
    this._referredService.getPartnerCustomer(this.customer)
    .subscribe( data => {
      $('#loader').hide();
      if(data.status == true){
        let cus:any = data.data;
        if(cus.doc_exp_date != null){
          let newDateDocExp = cus.doc_exp_date.split(" ");
          this.referred.doc_exp_date = newDateDocExp[0];
        }
        if(cus.birth_date != null){
          let newDateBd = cus.birth_date.split(" ");
          this.referred.birth_date = newDateBd[0];
        }
        this.referred.customer_id = cus.id;
        this.referred.doc_type_id = cus.doc_type_id;
        this.referred.name        = cus.name;
        this.referred.lastname    = cus.lastname;
        this.referred.email       = cus.email;
        this.referred.cellphone   = cus.cellphone;
        $( ".ng-invalid" ).addClass( "ng-touched" );
        $( ".ng-valid" ).addClass( "ng-touched" );
        if (cus.nickname_id != null) {
          this.referred.nickname_id = cus.nickname_id;
          this.referred.nickname    = cus.nickname;
        } else {
          this.referred.nickname_id = null;
          this.referred.nickname    = null;
        }
        this.data_policy.new_customer = false;
        this._ModalService.openModal('Información',  data.message, 'close', 'Aceptar');
      } else {
        this._ModalService.openModal('Información', data.message, 'close', 'Aceptar');
        $(".ng-invalid").removeClass("ng-touched");
        $(".ng-valid").removeClass("ng-touched");
        this.referred.customer_id     = null;
        this.referred.doc_exp_date    = null;
        this.referred.birth_date      = null;
        this.referred.name            = null;
        this.referred.lastname        = null;
        this.referred.email           = null;
        this.referred.cellphone       = null;
        this.referred.nickname_id     = null;
        this.referred.nickname        = null;
        this.data_policy.new_customer = true;
      }
    },
    error =>{
      console.error(error);
      $('#loader').hide();
    });
  }
}
