import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private http: HttpClient) {
  };

  getRegions() {
    return this.http.get("../../assets/json/colombia.min.json");
  };
}
