import { Component, OnInit } from '@angular/core';
import { ProfileService } from './../../services/profile.service';
import { environment } from './../../../environments/environment';
import { ModalService } from './../../services/modal-service.service';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms'
import { RegionService } from './../../services/region.service';
declare var $: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html'
})

export class ProfileComponent implements OnInit {

  profile: any = {
    partner_id: '',
    name: '',
    lastname: '',
    doc_type_id: '1',
    doc_num: '',
    birth_date: '',
    doc_exp_date: '',
    email: '',
    cellphone: '',
    gender: '',
    company_id: environment.company_id,
    address: '',
    depto_name:'',
    city_name:null,
    business_nit:null,
    business_name:null,
    business_address:null,
    business_phone:null
  };
 
  forma:FormGroup;
  // region dpto and city dv --
  regions:any=[];
  cities:any=[];

  constructor(
    private _ProfileService: ProfileService, 
    private _ModalService: ModalService, 
    private _RegionService: RegionService) {
      $('#loader').show();
      //Obtengo listado de regiones
      this._RegionService.getRegions().subscribe( data => {
        //asgino el listado a la variable del componente regions
        this.regions = data;
        //escoger grupo de ciudades por defecto
        this.cities= this.regions[0].ciudades;
        this.profile.city_name ='';
      },
      error => console.error(error));
      // info patners dv --
      this.getPartner(); 
  }

  ngOnInit() {
    this.forma = new FormGroup({
      'name': new FormControl('',  [
        Validators.required
      ]),
      'lastname': new FormControl('',[
        Validators.required
      ]),
      'doc_type_id': new FormControl(''),
      'doc_num': new FormControl('', [
        Validators.required,
        Validators.min(10000),
        Validators.max(10000000000),
      ]),
      'birth_date': new FormControl(''),
      'doc_exp_date': new FormControl(''),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern("[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?")
      ]),
      'cellphone': new FormControl('', [
        Validators.required,
        Validators.min(1000000000),
        Validators.max(10000000000),
        Validators.pattern("^(?!.*?([0123456789])\\1{9}).+"),
        this.numPhoneValidator
      ]),
      'gender': new FormControl(''),
      'company_id': new FormControl(''),
      'depto_name': new FormControl('', Validators.required),
      'city_name': new FormControl('', Validators.required),
      'address': new FormControl('')
    });

  }

  // info patners dv --
  getPartner() {
    let partnerId:string = localStorage.getItem('partner_id');
    this._ProfileService.getPartnerById(partnerId)
      .subscribe( data => {
        $('#loader').hide();
        if(data.status){
          this.profile.name = data.data.name;
          this.profile.lastname = data.data.lastname;
          this.profile.doc_type_id = data.data.doc_type_id;
          this.profile.doc_num = data.data.doc_num;
          this.profile.email = data.data.email;
          this.profile.cellphone = data.data.cellphone;
          this.profile.gender = data.data.gender;
          this.profile.address = data.data.address;
          this.profile.city_name = data.data.city_name;
          this.profile.depto_name = data.data.depto_name;
          // business info dv --
          this.profile.business_nit = data.data.business_nit;
          this.profile.business_name = data.data.business_name;
          this.profile.business_address = data.data.business_address;
          this.profile.business_phone = data.data.business_phone;
          // 
          if(data.data.doc_exp_date != null){
            let newDateDocExp = data.data.doc_exp_date.split(" ");
            this.profile.doc_exp_date = newDateDocExp[0];
          }
          //
          if(data.data.birth_date != null){
            let newDateSplit = data.data.birth_date.split(" ");
            this.profile.birth_date = newDateSplit[0];
          }
          //
          if (this.regions.find(departamento => departamento.departamento == this.profile.depto_name) != undefined) {
            this.cities = this.regions.find(departamento => departamento.departamento == this.profile.depto_name).ciudades;
          }else{
            this.profile.depto_name='';
            this.profile.city_name='';
          }
        }
        else{
          this._ModalService.openModal('Información',  data.message , 'close');
        }
      },
    error =>{
      $('#loader').hide();
      console.error('error');
    });
  }

  updatePartner()
  {
    $('#loader').show();

    localStorage.setItem('lastname', this.profile.lastname);
    localStorage.setItem('doc_type_id', this.profile.doc_type_id);
    localStorage.setItem('email', this.profile.email);
    localStorage.setItem('doc_num', this.profile.doc_num);
    localStorage.setItem('cellphone', this.profile.cellphone);
    localStorage.setItem('birth_date', this.profile.birth_date);
    localStorage.setItem('doc_exp_date', this.profile.doc_exp_date);
    localStorage.setItem('gender', this.profile.gender);
    localStorage.setItem('address', this.profile.address);
    localStorage.setItem('bank', this.profile.bank);
    localStorage.setItem('account_bank', this.profile.account_bank);
 
    if(this.profile.cellphone.length != 10){
      //this.profile.cellphone = "";
      this._ModalService.openModal('Información', 'Su numero de celular debe contener 10 digitos ' , 'check');
      $('#loader').hide();
      return false;
    }
    // validation digit repeat
    if(this.profile.doc_num != ""){
      let number  = this.profile.doc_num;
      let num_doc = new String(this.profile.doc_num);
      let doc     = num_doc.length - 1;
      var regExp  = new RegExp("^(?!.*?([0123456789])\\1{"+doc+"}).+");
       // --- true = some different, false = repeat
      if (regExp.test( number ) == false) {
        this._ModalService.openModal('Información', 'Los digitos de la Identificación no deben ser iguales' , 'check');
        $('#loader').hide();
        return false;
      }
    }

    let partnerId:string = localStorage.getItem('partner_id');
    this.profile.partner_id = partnerId;

    this._ProfileService.updatePartner(this.profile)
    .subscribe( data => {
      $('#loader').hide();
      if(data.status){
        this._ModalService.openModal('Información', 'Su perfil ha sido actualizado' , 'check');
      } else {
        this._ModalService.openModal('Información',  data.message , 'close');
      }
    },
    error =>{
      console.error(error);
      $('#loader').hide();
    });

  }

  getCities(event){
    if(event.target.value != ''){
      this.cities = this.regions.find(departamento => departamento.departamento == event.target.value).ciudades;
      this.profile.city_name = this.cities[0];
    } else {
      this.profile.city_name='';
    }
  }

  numPhoneValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if ( control.value>=0 ) {
      var phoneNumber =  (control.value + '').split('').map((i) => {return Number(i)})
      if ( phoneNumber.length>=1 && phoneNumber[0]!=3 ) {
        return { 'invalid_cellphone': true };
      }
    }
    return null;
  }
}
