import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Profile } from './../interfaces/profile';
import { environment } from './../../environments/environment';
import { Answer } from './../interfaces/answer';
import { SecurityService } from './../services/security.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient,  private _securityService: SecurityService) { }

  // get info patner by id dv --
  getPartnerById (partnerId:string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams().set("partner_id",partnerId);
    return this.http.get<Profile>( environment.api_url+'services/partners/getPartnerInfo', {headers: httpOptions.headers, params: params } );
  }
  
  // update partner dv --
  updatePartner( data: any) {
    return this.http.post<Answer>(  environment.api_url+'services/partners/updatePartnerInfo', data , this.getServiceToken( 'post' ))
          .pipe(
            map( res => {
              return res;
          }));
  }

  // change password partner dv --
  changePassword( data: any )
  {
    return this.http.post<Profile>(  environment.api_url+'services/partners/changePassword', data , this.getServiceToken( 'post' ))
          .pipe(
            map( res => {
              return res;
          }));
  }

  // header get dv --
  getServiceToken( method:string = 'get' )
  {
    if( method == 'get' )
    {
      return { headers: new HttpHeaders({ 'Content-Type':  'application/json' }) };
    }
    return { headers: new HttpHeaders({ 'Content-Type':  'application/json', 'Authorization':  localStorage.getItem('api_token') }) };
  }

}
