import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Answer } from '../interfaces/answer';
import { environment } from '../../environments/environment';
import { SecurityService } from './security.service';

@Injectable({
    providedIn: 'root'
})
export class LotteryService {
    constructor(
        private http: HttpClient,
        private _securityService: SecurityService)
    {}

    getLotteryActive (partner_id:any)
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type' : 'application/json',
                'Authorization': localStorage.getItem('api_token')
            }),
            params: new HttpParams()
                .set("partner_id", partner_id)
        };
        let data = this.http.get<Answer>( environment.api_url+'services/partners/getLotteryActive', httpOptions );
        this._securityService.processAnswerPipe(data);
        return data;
    }
}