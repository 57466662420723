import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Pqr } from './../interfaces/pqr';
import { Points } from './../interfaces/points';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PqrService {

  constructor( private http: HttpClient) { }

  sendPQR( pqr: any ) {
    const formTicket: FormData = new FormData();
    formTicket.append('company_id', pqr.company_id);
    formTicket.append('document_type_id', pqr.document_type_id);
    formTicket.append('document_number', pqr.document_number);
    formTicket.append('name', pqr.name);
    formTicket.append('lastname', pqr.lastname);
    formTicket.append('email', pqr.email);
    formTicket.append('cellphone', pqr.cellphone);
    formTicket.append('type_id', pqr.type_id);
    formTicket.append('message', pqr.message);
    formTicket.append('enable_notification', pqr.enable_notification);
    formTicket.append('attachment_type_id', pqr.attachment_type_id);
    if ( pqr.file ) {
      formTicket.append('file', pqr.file, pqr.file.name);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Cache-Control': 'no-cache, private',
        'Authorization': localStorage.getItem('api_token')
      })
    };
    return this.http.post<Pqr>( environment.pqr_url+'createTickets', formTicket, httpOptions )
          .pipe(
          map( res => {
            return res;
      },
      respError => {
        console.log('respError', respError);
      }));
  }

  getTicketTypes(){
    let company_id = 1;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.get<Request>( environment.pqr_url+'getTicketTypes/'+company_id, {headers: httpOptions.headers } );
  }

  getTicketsByPartner(documentType, documentNumber) {
    const httpOptions   = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    let params = new HttpParams()
                    .set("document_type", documentType)
                    .set("document_number", documentNumber);
    return this.http.get<Points>( environment.pqr_url+'getTicketsByCustomer', {headers: httpOptions.headers, params: params } );
  }

  getTicketsByPartnerPage(documentType, documentNumber, page) {
    const httpOptions   = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    let params = new HttpParams()
                    .set("document_type", documentType)
                    .set("document_number", documentNumber);
    return this.http.get<Points>( environment.pqr_url+'getTicketsByCustomer?tickets_list='+page, {headers: httpOptions.headers, params: params } );
  }

  getCommentsByTicketPartner(idPqr) {
    let ticket_id = idPqr;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.get<Points>( environment.pqr_url+'getCommentsByTicket/'+ticket_id, {headers: httpOptions.headers } );
  }

  saveComment( comment: any ) {
    const formComment: FormData = new FormData();
    formComment.append('ticket_id', comment.ticket_id);
    formComment.append('comment_type_id', comment.comment_type_id);
    formComment.append('comment', comment.comment);
    formComment.append('message', comment.message);
    if (comment.file != null ) {
      formComment.append('file', comment.file, comment.file.name);
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept'       : 'application/json',
        'Cache-Control': 'no-cache, private',
        'Authorization': localStorage.getItem('api_token')
      })
    };
    return this.http.post<Pqr>( environment.pqr_url+'saveComment', formComment, httpOptions )
          .pipe(
          map( res => {
            return res;
      },
      respError => {
        console.log('respError', respError);
      }));
  }

  getTicket(ticketId){
    let ticket_id = ticketId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.get<Points>( environment.pqr_url+'getTicket/'+ticket_id, {headers: httpOptions.headers } );
  }

}
