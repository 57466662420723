import { Component, OnInit } from '@angular/core';
import { ModalService } from './../../services/modal-service.service';
import { ProfileService } from './../../services/profile.service';
declare var $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {

  settings: any = {
    partner_id: '',
    password: '',
    password_confirmation: ''
  };

  constructor( private _ModalService:ModalService, private _ProfileService: ProfileService) { }

  ngOnInit() { }

  // change password dv --
  changePassword()
  {
    let validations = this.validateFields();
    if( validations.status )
    {
       this.settings.partner_id = localStorage.getItem('partner_id');
       this._ProfileService.changePassword( this.settings ).subscribe( data => {
        if( data.status )
        {          
          this._ModalService.openModal('Información', 'Contraseña actualizada correctamente' , 'check');
        }else
        {
          this._ModalService.openModal('Información', 'No pudimos actualizar tu contraseña' , 'close');
        }
      }, error =>{
        console.error(error);
      });

    }else
    {
      this._ModalService.openModal('Información', validations.message , 'close');
    }

  }


  validateFields()
  {
    if(this.settings.password == "" || this.settings.password_confirmation == "" )
    {
      return { 'status': false, 'message': 'Ingrese correctamente las contraseñas'};
    }
    if(this.settings.password != this.settings.password_confirmation)
    {
      return { 'status': false, 'message': 'Las contraseñas no coinciden'};
    }
    return { 'status': true, 'message': ''};
  }

}
