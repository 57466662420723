import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sub-banner',
  templateUrl: './sub-banner.component.html'
})
export class SubBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
