import { Component, OnInit } from '@angular/core';
import { Options } from 'ng5-slider';
import { Router } from '@angular/router';
import { SimulatorService } from './../../services/simulator.service';
import { ModalService } from './../../services/modal-service.service';
import { referredService } from './../../services/referred.service';
import { environment } from './../../../environments/environment';
import { document } from 'ngx-bootstrap/utils';
declare var $: any;

@Component({
    selector: 'app-simulator',
    templateUrl: './simulator.component.html',
})
export class SimulatorComponent implements OnInit {

    simulator:any = {
        credit_starts_date:'' ,
        credit_term_date :'',
        initial_value:'',
        credit_type_id:'',
        total_number_installments:'',
        optional_charges:0,
        customer_id:'',
        nickname_id:'',
        handling_fees: 1,
        bail: 0,
        management: 0,
        management_days : null,
    };
    
    partnerType:any = JSON.parse(localStorage.getItem('type'));

    waitrequest:any = false;

    charges:any= {
        'Capital':0,
    };

    contractInfo:any=[];

    stringDate :string="";
    amount: number = parseInt( this.partnerType.pre_request_min_amount );
    optionsAmount: Options = {
        floor: this.amount,
        ceil : parseInt( this.partnerType.pre_request_max_amount ),
        step : 10000,
        showSelectionBar: true,
        selectionBarGradient: {
            from: '#FAF464',
            to: '#726F2D'
        }
    };

    days:any = parseInt( this.partnerType.pre_request_max_days );
    optionsDays: Options = {
        floor: parseInt( this.partnerType.pre_request_min_days ),
        ceil : parseInt( this.partnerType.pre_request_max_days ),
        step : 1,
        showSelectionBar: true,
        selectionBarGradient: {
            from: '#FAF464',
            to  : '#726F2D'
        }
    };

    constructor(
        private _Simulator: SimulatorService,
        private _referredService: referredService,
        private router: Router, 
        private _ModalService: ModalService)
    {
        window.scroll(0,0);
        $('#loader').show();
        this.getDataBail();
        this.getHandlingFee();
        this.getDataTypeCredit( this.partnerType.credit_type_id );
    }

    ngOnInit()
    {
        $(document).ready(function(){
            $('.tooltipped').tooltip();
        });
    }

    getPartnerContractInfo()
    {
        let partnerId:string = localStorage.getItem('partner_id');
        this._Simulator.getPartnerContractInfo(partnerId).subscribe(
            response => {
                if ( response.status ) {
                    this.contractInfo = response["data"];
                    if ( this.partnerType.id == 1 && this.contractInfo.available_amount < 1000000 ) {
                        this.partnerType.pre_request_max_amount = this.contractInfo.available_amount;
                    }
                    //Opciones de los dias
                    const optionsDays: Options = Object.assign({}, this.optionsDays);
                    optionsDays.floor = parseInt( this.partnerType.pre_request_min_days );
                    optionsDays.ceil  = parseInt( this.partnerType.pre_request_max_days );
                    this.optionsDays  = optionsDays;
                    
                    //Opciones del valor
                    const optionsAmount: Options = Object.assign({}, this.optionsAmount);
                    optionsAmount.floor = parseInt( this.partnerType.pre_request_min_amount );
                    optionsAmount.ceil  = parseInt( this.partnerType.pre_request_max_amount );
                    this.optionsAmount  = optionsAmount;
                    
                    this.simulator.credit_starts_date        = this.formatStringDate(new Date());
                    this.simulator.credit_term_date          = this.formatStringDate(this.todaySumDays( parseInt( this.days ) ) );
                    this.simulator.initial_value             = this.partnerType.pre_request_min_amount;
                    this.simulator.total_number_installments = 1;
                    this.stringDate                          = this.setStringDate(this.todaySumDays( parseInt( this.days ) ) );
                    
                    this.simulate();
                }
            },
            error => {
                console.error(error);
                $('#loader').hide();
            }
        );
    }

    createPreRequest()
    {
        let preRequest: any = {
            partner_id        : localStorage.getItem('partner_id'),
            customer_id       : localStorage.getItem('customer_id'),
            nickname_id       : localStorage.getItem('nickname_id'),
            credit_type_id    : this.partnerType.credit_type_id,
            amount            : this.simulator.initial_value,
            credit_term_date  : this.simulator.credit_term_date,
            credit_starts_date: this.simulator.credit_starts_date,
            credit_days       : this.days,
            optional_charges  : this.simulator.optional_charges,
            handling_fees     : this.simulator.handling_fees,
            bail              : this.simulator.bail,
            management        : this.simulator.management,
            management_days   : this.simulator.management_days
        };

        this._referredService.createPreRequest(preRequest).subscribe(
            response => {
                $('#loader').hide();
                if( response.status ) {
                    this._ModalService.openModal('Información', response.message , 'check');
                    this.router.navigate(['/secure/new-referral']);
                } else {
                    this._ModalService.openModal('Información',  response.message , 'close');
                }
            },
            error =>{
                console.error(error);
                $('#loader').hide();
            }
        );
    }

    simulate()
    {
        this.waitrequest = true;
        document.getElementById('amount-simulator').style.display = 'none';
        document.getElementById('preloader').style.display = 'block'; 
        this._Simulator.simulator(this.simulator).subscribe(
            response => {
                $('#loader').hide();
                if(response.status){
                    let charges = [];
                    const values = Object.values(response.data[0]);
                    for (const value of values) {
                        charges.push(value);
                    }
                    this.charges = charges;
                    this.waitrequest = false;
                    document.getElementById('preloader').style.display='none';
                    document.getElementById('amount-simulator').style.display = 'block';
                }
                else {
                    this._ModalService.openModal('Hola Amig@', 'Ocurrió un error consultando la información' , 'close', 'Continuar', '#/secure/home');
                }
            },
            error => {
                this.waitrequest = false;
                document.getElementById('preloader').style.display='none';
                document.getElementById('amount-simulator').style.display = 'block';
                this._ModalService.openModal('Hola Amig@', 'Ocurrió un error consultando la información' , 'close', 'Continuar', '#/secure/home');
                $('#loader').hide();
                console.error(error);
            }
        );
    }

    updateSimulator()
    {
        var today = new Date();
        var newdate = new Date();
        newdate.setDate(today.getDate()+this.days);
        this.stringDate = this.setStringDate(newdate);
        this.simulator.credit_starts_date = this.formatStringDate(new Date());
        this.simulator.credit_term_date   = this.formatStringDate(this.todaySumDays(this.days));
        this.simulator.initial_value      = this.amount;
        this.simulate();
    }

    // validation value checkbox express dv-
    checkValue(event)
    {
        this.simulator.optional_charges = event;
        this.updateSimulator();
    }

    setStringDate(date:any)
    {
        var result = date;
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return result.toLocaleDateString("es-ES", options);
    }

    formatStringDate(date:any)
    {
        var tzoffset = (date).getTimezoneOffset() * 60000;
        var localISOTime = (new Date(date - tzoffset)).toISOString().slice(0, -5);
        let dates = localISOTime.split('T');
        localISOTime = dates[0];
        return localISOTime;
    }

    todaySumDays(days)
    {
        var today = new Date();
        var newdate = new Date();
        newdate.setDate(today.getDate()+days);
        return newdate
    }

    getDataTypeCredit( credit_type_id:any )
    {
        this._Simulator.getDataTypeCredit( credit_type_id ).subscribe(
            response => {
                if( response.status ) {
                    let credit_type:any = response.data;
                    this.simulator.credit_type_id = parseInt( credit_type.core_credit_type_id );
                }
                this.getPartnerContractInfo();
            },
            error => {
                console.error(error);
            }
        );
    }

    // get bail admin 
    getDataBail(){
        let customer_id = localStorage.getItem('customer_id');
        this._Simulator.getDataBail(customer_id).subscribe(
            response => {
               if ( response.status ) {
                let databail: any = response.data;
                this.simulator.bail = parseInt(databail.bail);
                this.simulator.management = parseInt(databail.quota_admin);
               }
            },
            error => {
                console.error(error);
            }
        );
    }

    getHandlingFee(){
        let customer_id = localStorage.getItem('customer_id');
        this._Simulator.getHandlingFee(customer_id).subscribe(
            response => {
                if ( response.status) {
                    let handlingfee = response.data;
                    this.simulator.management_days = handlingfee['end_date']; 
                }
            },
            error => {
                console.error(error)
            }
        );
    }

}
