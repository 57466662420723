import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Recommended } from '../interfaces/recommended';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})

export class WalletService {

  constructor(private http: HttpClient,  private _securityService: SecurityService) { }

  getTotalRedemptionPartner(partnerId:string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams().set("partner_id",partnerId);
    return this.http.get<Recommended>( environment.api_url+'services/partners/getTotalRedemptionPartner', {headers: httpOptions.headers, params: params } );
  }

  getPartnerCustomerCount(partnerId:string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams().set("partner_id",partnerId);
    return this.http.get<Recommended>( environment.api_url+'services/partners/getPartnerCustomerCount', {headers: httpOptions.headers, params: params } );
  }

  getRedemptionsTypes(partnerId:string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams().set("partner_id",partnerId);
    return this.http.get<Recommended>( environment.api_url+'services/partners/getRedemptionsTypes', {headers: httpOptions.headers, params: params } );
  }

  getIncomesPartner1( data: any ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    return this.http.post<Recommended>( environment.api_url+'services/partners/getIncomesPartner', data , httpOptions )
          .pipe(
          map( res => {
            return res;
          }));
  }

  getIncomesPartner(partnerId:string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams().set("partner_id",partnerId);
    return this.http.get<Recommended>( environment.api_url+'services/partners/getIncomesPartner', {headers: httpOptions.headers, params: params } );
  }

  getIncomesPartnerPage(partnerId:string, page) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams().set("partner_id",partnerId);
    return this.http.get<Recommended>( environment.api_url+'services/partners/getIncomesPartner?incomes_detail_list='+page, {headers: httpOptions.headers, params: params } );
  }

  getRedemptionIncomePartner(data: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams()
                    .set("partner_id", data.partner_id)
                    .set("redemption_type_id", data.redemption_type_id);
    return this.http.post<Recommended>( environment.api_url+'services/partners/redemptionMoneyPartner', data , httpOptions )
                    .pipe(
                      map( res => {
                        return res;
                      }));
  }

}
