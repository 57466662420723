import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Answer } from './../interfaces/answer';
import { environment } from './../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SimulatorService {

    constructor(private http: HttpClient) { }

    simulator( data: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization':  localStorage.getItem('api_token')
            })
        };
        return this.http.post<Answer>(  environment.api_url+'services/simulateCredit', data , httpOptions )
                    .pipe(
                    map( res => {
                        return res;
                    }));
    }

    getPartnerContractInfo( partnerId:string ) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization':  localStorage.getItem('api_token')
            })
        };
        let params = new HttpParams().set("partner_id",partnerId);
        return this.http.get<Answer>( environment.api_url+'services/partners/getPartnerContractInfo', {headers: httpOptions.headers, params: params } );
    }

    getDataTypeCredit( credit_type_id:any )
    {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization':  localStorage.getItem('api_token')
            }),
            params : new HttpParams()
                        .set("credit_type_id", credit_type_id)
                        .set("partner_id", localStorage.getItem('partner_id') )
        };
        return this.http.get<Answer>( environment.api_url+'services/partners/getTypeCredit', httpOptions );
    }

    getDataBail( customerId : any ){

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization':  localStorage.getItem('api_token')
            }),
            params: new HttpParams().set("customerId", customerId)
        }; 

        return this.http.get<Answer>( environment.api_url+'services/getBail', httpOptions)
                .pipe(
                    map( res => {
                        return res;
                    })
                )
    }

    getHandlingFee( customerId : any ){
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json',
                'Authorization':  localStorage.getItem('api_token')
            }),
            params: new HttpParams().set("customer_id", customerId)
          };

        return this.http.get<Answer>( environment.api_url+'services/getcustomerHandlingFee', httpOptions)
    }
                
}
