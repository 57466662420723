import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-side-menu',
    templateUrl: './side-menu.component.html'
})
export class SideMenuComponent implements OnInit {
    
    constructor() {}
  
    ngOnInit() {}
}
