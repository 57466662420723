import { Component, OnInit } from '@angular/core';
import { ModalService } from './../../services/modal-service.service';
import { WalletService } from './../../services/wallet.service';
declare var $: any;

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html'
})
export class WalletComponent implements OnInit {

  totalRedemption:any=[];
  customerCount:any=[];
  redemptionsTypes:any=[];
  redemptionsType:any = '';
  listIncomes:any=[];
  min_value_redeem:any;

  showBoundaryLinks = true;
  maxSize = 10;
  bigTotalItems = 0;
  bigCurrentPage = 0;

  constructor(
    private _walletService: WalletService,   
    private _ModalService: ModalService) { 
      this.getTotalRedemptionPartner();
      this.getPartnerCustomerCount();
      this.getRedemptionsTypes();
      this.getIncomesPartner();
      $('#loader').show();
      this.min_value_redeem = localStorage.getItem('min_value_redeem');
  }

  ngOnInit() {
  }

  getTotalRedemptionPartner() {
    let partnerId:string = localStorage.getItem('partner_id');
    this._walletService.getTotalRedemptionPartner(partnerId)
    .subscribe( data => {
      if(data.status){
        this.totalRedemption = data["data"];
        setTimeout(() => {
          $('#loader').hide();
        }, 500);
      }
    },
    error => console.error(error));
      $('#loader').hide();
  }

  getPartnerCustomerCount() {
    let partnerId:string = localStorage.getItem('partner_id');
    this._walletService.getPartnerCustomerCount(partnerId)
    .subscribe( data => {
      if(data.status){
        this.customerCount = data["data"];
        setTimeout(() => {
          $('#loader').hide();
        }, 500);
      }
    },
    error => console.error(error));
      $('#loader').hide();
  }

  getRedemptionsTypes() {
    let partnerId:string = localStorage.getItem('partner_id');
    this._walletService.getRedemptionsTypes(partnerId)
    .subscribe( data => {
      if(data.status){
        this.redemptionsTypes = data["data"];
        setTimeout(() => {
          $('#loader').hide();
        }, 500);
      }
    },
    error => console.error(error));
      $('#loader').hide();
  }

  getIncomesPartner() {
    let partnerId:string = localStorage.getItem('partner_id');
    this._walletService.getIncomesPartner(partnerId)
    .subscribe( data => {
      if(data.status){
        this.listIncomes = data["data"];
        setTimeout(() => {
          this.bigTotalItems = this.listIncomes.total;
          this.bigCurrentPage = this.listIncomes.current_page;
          $('#loader').hide();
        }, 500);
      }
    },
    error => console.error(error));
      $('#loader').hide();
  }

  getRedemptionIncomePartner() {
    if (this.redemptionsType == '') {
      this._ModalService.openModal('Información', 'Seleccione un tipo' , 'check');
      $('#loader').hide();
      return false;
    }
    // validation value minim redemption 
    if (this.min_value_redeem > this.totalRedemption) {
      this._ModalService.openModal('Información', 'No cuenta con el dinero mínimo para redimir' , 'check');
      $('#loader').hide();
      return false;
    }

    let partnerId:string = localStorage.getItem('partner_id');
    let data: any = {
      partner_id: partnerId,
      redemption_type_id: this.redemptionsType,
    };

    this._walletService.getRedemptionIncomePartner(data)
    .subscribe( data => {
      if(data.status){
        this._ModalService.openModal('Mensaje', 'Solicitud enviada correctamente', 'check');
        setTimeout(() => {
          this.getTotalRedemptionPartner();
          this.getPartnerCustomerCount();
          $('#loader').hide();
        }, 500);
      }else{
        this._ModalService.openModal('Mensaje', 'Ocurrió un error enviando la solicitud' , 'close');
      }
    },
    error => console.error(error));
      setTimeout(() => {
        $('#loader').hide();
      }, 500);
  }
  pageChanged(event: any): void {
    $('#loader').show();
    let page:string = event.page;
    let partnerId:string = localStorage.getItem('partner_id');
    this._walletService.getIncomesPartnerPage(partnerId, page)
    .subscribe( data => {
      if(data.status){
        this.listIncomes = data.data;
        this.bigTotalItems = this.listIncomes.total;
        this.bigCurrentPage = this.listIncomes.current_page;
        $('#loader').hide();
      } else {
          this.listIncomes = data.data;
      }
    },
    error => console.error(error));
    $('#loader').hide();
  }
}
