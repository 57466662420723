import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from './../../services/login.service';
import { environment } from './../../../environments/environment';
import { ModalService } from './../../services/modal-service.service';
import { LotteryService } from './../../services/lottery.service';
import { stringify } from '@angular/core/src/util';
declare var $: any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
	// Var login
	login: any = {
		nickname: '',
		password: '',
		company_id :environment.company_id,
		email:''
	};

	settings: any = {
		partner_id: '',
		password: '',
		password_confirmation: ''
	};
	
	constructor(
        private activatedRoute: ActivatedRoute, 
		private _LoginService: LoginService, 
		private _ModalService: ModalService,
		private router: Router,
		private _lotteryService: LotteryService)
	{
		window.scroll(0,0);
		this.validateSesion();
		this.activatedRoute.params.subscribe( params =>{   
			localStorage.setItem("source", params['source'] );
		});
	}

	//
	ngOnInit()
    {
		$(document).ready(function(){
			$('.modal').modal();
		});
	}

	// login  dv --
	doLogin() {
        this.login.email = this.login.nickname;
        this._LoginService.login(this.login).subscribe(
            response => {
                if( response.status ) {
                    let partner:any = response.data;
                    if ( partner.is_new_password == 1 ) {
                        this.settings.partner_id = partner.id;
                        localStorage.setItem('api_token', partner.api_token);
                        $('.modal').modal();
                        $('#modalChangePassword').modal('open');
                    } else {
                        localStorage.setItem('api_token', partner.api_token);
                        localStorage.setItem('is_new_password', partner.is_new_password)
                        localStorage.setItem('name', partner.name);
                        localStorage.setItem('lastname', partner.lastname);
                        localStorage.setItem('doc_type_id', partner.doc_type_id);
                        localStorage.setItem('birth_date', partner.birth_date);
                        localStorage.setItem('doc_exp_date', partner.doc_exp_date);
                        localStorage.setItem('email', partner.email);
                        localStorage.setItem('doc_num', partner.doc_num);
                        localStorage.setItem('partner_id', partner.id);
                        localStorage.setItem('cellphone', partner.cellphone);
                        localStorage.setItem('gender', partner.gender);
                        localStorage.setItem('address', partner.address);
                        localStorage.setItem('code', partner.code);
						localStorage.setItem('type', JSON.stringify(partner.type));
                        localStorage.setItem('activeLottery', 'false');
                        this._lotteryService.getLotteryActive( partner.id ).subscribe(
                            response => {
                                if ( response.status ) {
                                    localStorage.setItem('activeLottery', 'true');
                                }
                                // redirect home
                                this.router.navigate(['/secure/home']);
                            },
                        );
                    }
                } else {
                    this._ModalService.openModal('No se puede entrar', 'Tus datos no coinciden, valida tus datos o revisa que tu cuenta esté verificada' , 'close');
                }
            },
            error => console.error(error)
        );
	}

	// validate  sesión dv --
	validateSesion()
	{
		if( localStorage.length > 10 ) {
			this.router.navigate(['secure/home']);
		}
	}

	// change password dv --
	changePassword()
	{
		let validations = this.validateFields();
		if( validations.status ) {
			this._LoginService.changePassword( this.settings ).subscribe(
                response => {
					if( response.status ) {  
						this.clearLogin(); 
						$('#modalChangePassword').modal('close');     
						this._ModalService.openModal('Información', response.message , 'check');
					} else {
						this.clearLogin();
						$('#modalChangePassword').modal('close');  
						this._ModalService.openModal('Información', 'No pudimos actualizar tu contraseña' , 'close');
					}
				}, error =>{
					console.error(error);
				}
            );
		} else {
			this._ModalService.openModal('Información', validations.message , 'close');
		}
	}

	validateFields()
	{
		if(this.settings.password == "" || this.settings.password_confirmation == "" ) {
			return { 'status': false, 'message': 'Ingrese correctamente las contraseñas'};
		}
		if(this.settings.password != this.settings.password_confirmation) {
			return { 'status': false, 'message': 'Las contraseñas no coinciden'};
		}
		return { 'status': true, 'message': ''};
	}

	clearLogin(){
		let lang=localStorage.getItem('lang');
		localStorage.clear();
		localStorage.setItem('lang', lang);
	}
}