import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PqrService } from './../../services/pqr.service';
import "materialize-css";
declare var $: any;

@Component({
  selector: 'app-pqr-list',
  templateUrl: './pqr-list.component.html'
})
export class PqrListComponent implements OnInit {

  pqrs:any=[];
  constructor(
    private _pqrService: PqrService, 
    private router: Router) {
    this.getTicketsByPartner();
    $('#loader').show();
  }

  showBoundaryLinks = true;
  maxSize           = 5;
  bigTotalItems     = 0;
  bigCurrentPage    = 0;

  ngOnInit() {
  }

  goToDetail(idPqr) {
    localStorage.setItem('currentPqr', idPqr);
    this.router.navigate( ['/secure/pqr-detail'] );
  }

  getTicketsByPartner() {
    let partnerTypeDoc = localStorage.getItem('doc_type_id');
    let partnerDoc     = localStorage.getItem('doc_num');
    this._pqrService.getTicketsByPartner( partnerTypeDoc, partnerDoc )
    .subscribe( data => {
      let reponse:any=data;
      if(reponse.status == 1){
        this.pqrs           = data.data;
        setTimeout(() => {
          this.bigTotalItems  = this.pqrs.total;
          this.bigCurrentPage = this.pqrs.current_page;
          $('#loader').hide();
        }, 500);
      } else {
        setTimeout(() => {
          $('#loader').hide();
        }, 500);
      }
    },
    error => console.error(error));
      $('#loader').hide();
  }

  pageChanged(event: any): void {
    let partnerTypeDoc = localStorage.getItem('doc_type_id');
    let partnerDoc     = localStorage.getItem('doc_num');
    let page: string = event.page;
    this._pqrService.getTicketsByPartnerPage( partnerTypeDoc, partnerDoc, page )
    .subscribe( data => {
      let reponse:any=data;
      if(reponse.status == 1){
        this.pqrs           = data.data;
        this.bigTotalItems  = this.pqrs.total;
        this.bigCurrentPage = this.pqrs.current_page;
      } else {
        this.pqrs = data["data"];
      }
    },
    error =>{
      console.error(error);
    });
  }
}
