import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestsService } from '../../services/requests.service';
import { ModalService } from '../../services/modal-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip} from 'ng2-charts';
import "materialize-css";
declare var $: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
    requests:any=[];
    pre_requests:any=[];
    contract:any=[];
    contractflag:boolean=false;
    requestsData: any[] = [];
    preData: any[] = [];
    percentage: number = 0;
    current_amount: number = 0;
    available_amount: number = 0;
    mymoney:any;
    partnerType:any = JSON.parse(localStorage.getItem('type'));
    
    activeLottery:boolean = (localStorage.getItem('activeLottery') == 'true');

    // torta
    public pieChartLabels: Label[] = ["Solicitudes al Dia", "Solicitudes en Mora"];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartData: number[] = [0,0];
    public pieChartPlugins = [];
    public pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            position: 'bottom',
        },
        plugins: {
            datalabels: {
                formatter: (value, ctx) => {
                    const label = ctx.chart.data.labels[ctx.dataIndex];
                    return label;
                },
            },
        }
    };
    public pieChartColors = [
        {
            backgroundColor: ["#2b957a","#525ac7"]
        },
    ];
    
    // barras
    public barChartLabels: Label[] = ["Consolidado de estado de presolicitud"];
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [];
    public barChartData: ChartDataSets[] = [
        { data: [0], label: 'Nueva' },
    ];
    public barChartOptions: ChartOptions = {
        responsive: true,
        scales: {
            yAxes: [{
                ticks: {
                    min         : 0,
                    beginAtZero : true
                }
            }]
        },
        legend: {position: 'bottom'}
    };
    
    constructor(
        private _requestsService: RequestsService, 
        private router: Router,
        private translate: TranslateService,
        private _ModalService: ModalService)
    {
        monkeyPatchChartJsTooltip();
        monkeyPatchChartJsLegend();
    }

    ngOnInit() {
        $('#loader').show();
        let partnerId = localStorage.getItem('partner_id');
        this.getDashboardInfo( partnerId );
        this.getRedemptionsInfo( partnerId );
    }
    
    getDashboardInfo( partnerId )
    {
        this._requestsService.getDashboardInfo(partnerId).subscribe(
            data => {
                if(data.status){
                    // info contract
                    this.contract=data.data["contract"];
                    // info pre request
                    this.pre_requests=data.data["pre_requests"];
                    //
                    setTimeout(() => {
                        // validation contract active
                        if (this.contract != null) {
                            this.contractflag=true;
                            localStorage.setItem('min_value_redeem', this.contract.min_value_redeem);
                            this.available_amount=this.contract.available_amount;
                            this.current_amount=this.contract.current_amount;
                            if (Number(this.current_amount) >= Number(this.available_amount) ) {
                                this.percentage = (this.available_amount/this.current_amount)*100;
                            } 
                        }else {
                            this.contractflag=false;
                            this._ModalService.openModal('Información', this.translate.instant('lang.dashboard.contract') , 'check');
                        }

                        if (this.pre_requests != null) {
                            this.requestsData = [this.pre_requests.course,this.pre_requests.non_payment];
                            this.pieChartData = this.requestsData;
                            this.preData = [
                                { 
                                    data: [this.pre_requests.news], 
                                    label: this.translate.instant('lang.dashboard.new'), backgroundColor: '#5bc0de'
                                },{ 
                                    data: [this.pre_requests.progress], 
                                    label: this.translate.instant('lang.dashboard.progres'), backgroundColor: '#428bca'
                                },{ 
                                    data: [this.pre_requests.course], 
                                    label: this.translate.instant('lang.dashboard.curso'), backgroundColor: '#2b957a'
                                },{ 
                                    data: [this.pre_requests.denied], 
                                    label: this.translate.instant('lang.dashboard.rejeted'), backgroundColor: '#d9534f'
                                },{ 
                                    data: [this.pre_requests.non_payment], 
                                    label: this.translate.instant('lang.dashboard.mora'), backgroundColor: '#525ac7'
                                },{ 
                                    data: [this.pre_requests.paid], 
                                    label: this.translate.instant('lang.dashboard.pay'), backgroundColor: '#5cb85c'
                                },{ 
                                    data: [this.pre_requests.cancel], 
                                    label: this.translate.instant('lang.dashboard.cancel'), backgroundColor: '#f3ca06'
                                },{ 
                                    data: [this.pre_requests.desist], 
                                    label: this.translate.instant('lang.dashboard.desist'), backgroundColor: '#f0ad4e'
                                }
                            ];
                            this.barChartData = this.preData;
                        }
                    }, 500);
                }
            },
            error =>{
                console.error(error);
                $('#loader').hide();
            }
        );
    }
    
    getRedemptionsInfo( partnerId )
    {
        this._requestsService.getTotalRedemptionPartner(partnerId).subscribe(
            data => {
                if(data.status){
                    this.mymoney = data.data;
                    setTimeout(() => {
                        $('#loader').hide();
                    }, 500);
                }
            },
            error =>{
                console.error(error);
                $('#loader').hide();
            }
        );
    }

    // events on slice click
    public chartClicked(e:any):void {
        console.log(e);
    }
 
    // event on pie chart slice hover
    public chartHovered(e:any):void {
        console.log(e);
    }
    
    showLottery()
    {
        $('#loader').show();
        this.router.navigate(['/secure/lottery']);
    }
}
