import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RecommendedService } from './../../services/recommended.service';
import { ModalService } from './../../services/modal-service.service';
import { referredService } from './../../services/referred.service';
declare var $: any;

@Component({
  selector: 'app-help',
  templateUrl: './recommended.component.html'
})

export class RecommendedComponent implements OnInit {

  recommendeds:any=[];
  statuses:any=[];

  filters: any = {
    partner_id: '',
    status: '',
    filter: '',
  };

  showBoundaryLinks = true;
  maxSize = 5;
  bigTotalItems = 0;
  bigCurrentPage = 0;

  constructor(private router: Router,
    private _recommendedService: RecommendedService,   
    private _ModalService: ModalService,
    private _referredService: referredService) {
      this.getRecommendeds();
      this.getPreRequestStatus();
      //partners/getPreRequestStatus
      $('#loader').show();
  }

  ngOnInit() {
    //
  }
  //
  getRecommendeds() {
    let partnerId:string = localStorage.getItem('partner_id');
    this.filters.partner_id = partnerId;
    this._recommendedService.getPreRequestPartner(this.filters)
    .subscribe( data => {
      if(data.status){
        this.recommendeds = data.data;
        setTimeout(() => {
          this.bigTotalItems = this.recommendeds.total;
          this.bigCurrentPage = this.recommendeds.current_page;
          $('#loader').hide();
        }, 500);
      } else {
        this.recommendeds = data["data"];
        setTimeout(() => {
          $('#loader').hide();
        }, 500);
      }
    },
    error =>{
      console.error(error);
      $('#loader').hide();
    });
  }

  getPreRequestStatus() {
    let partnerId:string = localStorage.getItem('partner_id');
    this._recommendedService.getPreRequestStatus(partnerId)
    .subscribe( data => {
      if(data.status){
        this.statuses = data["data"];
        setTimeout(() => {
          $('#loader').hide();
        }, 500);
      }
    },
    error =>{
      console.error(error);
      $('#loader').hide();
    });
  }

  changeStatus(event): void {
    this.getRecommendeds();
    $('#loader').show();
  }
  
  goToDetail(idRecommended) {
    localStorage.setItem('currentRecommended', idRecommended);
    this.router.navigate( ['/secure/history'] );
  }

  goToReturn(preRequestId) {
    localStorage.setItem('pre_request_id', String(preRequestId));
    this._ModalService.openModal('Información', 'Retomar la presolicitud n°  '+preRequestId , 'check');
    this.router.navigate(['/secure/simulator']);
  }

  cancelStatus(preRequestId) {
    $('#loader').show();
    let preRequest: any = {
      partner_id:localStorage.getItem('partner_id'),
      pre_request_id: preRequestId,
      pre_request_status_id: 7
    };
    this._referredService.updatePreRequest(preRequest)
    .subscribe( data => {
      if(data.status){
        this.getRecommendeds();
        this._ModalService.openModal('Información', data.message , 'check');
      }else{
        $('#loader').hide();
        this._ModalService.openModal('Información',  data.message , 'close');
      }
    },
    error =>{
      console.error(error);
      $('#loader').hide();
    });
  }

  pageChanged(event: any): void {
    $('#loader').show();
    let page:string = event.page;
    let partnerId:string = localStorage.getItem('partner_id');
    this.filters.partner_id = partnerId;
    this._recommendedService.getPreRequestPartnerPage(this.filters,page)
    .subscribe( data => {
      if(data.status){
        this.recommendeds = data.data;
          this.bigTotalItems = this.recommendeds.total;
          this.bigCurrentPage = this.recommendeds.current_page;
          $('#loader').hide();
      } else {
        this.recommendeds = data["data"];
      }
    },
    error =>{
      console.error(error);
      $('#loader').hide();
    });
  }
}