import { Component, OnInit } from '@angular/core';
import { RequestsService } from './../../../services/requests.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-main-menu-buttons',
    templateUrl: './main-menu-buttons.component.html',
})
export class MainMenuButtonsComponent implements OnInit {
    
    partnerType:any = JSON.parse(localStorage.getItem('type'));

    constructor(
        private _requestsService: RequestsService, 
        private router: Router) 
    {}

    ngOnInit()
    {}
}