import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PqrService } from './../../services/pqr.service';
import { environment } from './../../../environments/environment';
import { ModalService } from './../../services/modal-service.service';
declare var $: any;


@Component({
  selector: 'app-pqr',
  templateUrl: './pqr.component.html'
})

export class PqrComponent implements OnInit {

  pqr:any={
    company_id         : environment.company_id,
    document_type_id   : '',
    document_number    : '',
    name               : localStorage.getItem('name'),
    lastname           : localStorage.getItem('lastname'),
    email              : '',
    cellphone          : '',
    type_id            : '1',
    message            : '',
    enable_notification: 1,
    attachment_type_id : 3,
    file               : null
  };
  types:any=[];

  constructor(
    private _pqrService: PqrService, 
    private _ModalService: ModalService,
    private router: Router) {

      if(!this.checkProfileComplete())
      {
        this._ModalService.openModal('Información', 'Por favor complete la información de su perfil antes de hacer esta acción' , 'info');
        this.router.navigate( ['/secure/profile'] );
      } else {
        this.pqr.company_id       = environment.company_id;
        this.pqr.document_type_id = localStorage.getItem('doc_type_id');
        this.pqr.document_number  = localStorage.getItem('doc_num');
        this.pqr.name             = localStorage.getItem('name');
        this.pqr.lastname         = localStorage.getItem('lastname');
        this.pqr.email            = localStorage.getItem('email');
        this.pqr.cellphone        = localStorage.getItem('cellphone');
        this.getTicketTypes();
        $('#loader').show();
      }
    }

  ngOnInit() {
  }

  sendPQR(){
    this._pqrService.sendPQR(this.pqr)
    .subscribe( data => {
      if(data.status){
        this._ModalService.openModal('Mensaje', 'PQR enviado correctamente Su numero de radicado :'+data.ticket_id , 'check');
        this.router.navigate( ['/secure/pqr'] );
      } else{
        this._ModalService.openModal('Mensaje', 'Ocurrió un error enviando el PQR' , 'close');
      }
    },
    error => console.error(error));
  }

  getTicketTypes(){
    this._pqrService.getTicketTypes()
    .subscribe( data => {
     this.types = data;
      setTimeout(() => {
        $('#loader').hide();
      }, 500);
    },
    error => console.error(error));
  }
  handleFileInput(files: FileList) {
    this.pqr.file = files.item(0);
  }
  checkProfileComplete(){
    if(localStorage.getItem('doc_num')=='null' || localStorage.getItem('doc_num')=='' )
    {
      return false;
    } else {
      return true;
    }
  }
}