import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LotteryService } from './../../services/lottery.service';
declare var $: any;

@Component({
    selector: 'app-lottery',
    templateUrl: './lottery.component.html'
})
export class LotteryComponent implements OnInit {
    
    activeLottery:boolean = false;
    lotteryData:any = {
        draw_date    : "",
        id           : 0,
        name         : "",
        number_length: ""
    };
    lotteryNumbers:any;

    constructor(
        private _lotteryService: LotteryService, 
        private router: Router) 
    { 
        $('#loader').show();
        this.getLotteryNumber();
    }

    ngOnInit() {
    }

    getLotteryNumber()
    {
        let lotteryData = [];
        let partner_id = localStorage.getItem('partner_id');
        this._lotteryService.getLotteryActive( partner_id ).subscribe(
            response => {
                if ( response.status ) {
                    let lotteryNumbers = [];
                    this.activeLottery = true;
                    lotteryData = response.data;
                    if ( lotteryData['numbers'] ) {
                        lotteryData['numbers'].forEach(lottery => {
                            lotteryNumbers.push( this.processLotteryNumber(lottery) );
                        });
                    }
                    this.lotteryData = lotteryData;
                    var draw_date = new Date(this.lotteryData.draw_date.replace(/\s+/g, 'T'));
                    this.lotteryData.draw_date = draw_date.toLocaleDateString("es-ES", { year: 'numeric', month: 'long', day: 'numeric'});
                    this.lotteryNumbers = lotteryNumbers;
                    $('#loader').hide();
                } else {
                    localStorage.setItem('activeLottery', 'false');
                    this.router.navigate(['/secure/home']);
                }
            },
            error =>{
                console.error(error);
                this.router.navigate(['/secure/home']);
            }
        );
    }
    
    processLotteryNumber(lottery:any) 
    {
        var numbers = lottery.number_played.split('');
        var date_played = new Date(lottery.date_played.replace(/\s+/g, 'T'));
        return {
            nickname   : lottery.nickname,
            serie      : numbers[0]+numbers[1]+numbers[2],
            number     : numbers[3]+numbers[4]+numbers[5]+numbers[6],
            date_played: date_played.toLocaleDateString("es-ES", { month: 'long', day: 'numeric' })
        };
    }
}
