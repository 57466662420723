import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-floating-buttons',
  templateUrl: './floating-buttons.component.html',
  styleUrls: ['./floating-buttons.component.css']
})
export class FloatingButtonsComponent implements OnInit {

  facebook_url: string;
  instagram_url: string;
  whatsapp: string;
  mail: string;
  screen_available: boolean = false;


  constructor() {

    this.facebook_url = environment.facebook_url;
    this.instagram_url = environment.instagram_url;
    this.whatsapp = environment.whatsapp;
    this.mail = environment.mail;

    this.resizeScreen();


  }

  ngOnInit() {
  }

  resizeScreen()
  {
    if( screen.width > 992 ){
      this.screen_available = true;
    }
  }


}
