import { Injectable } from '@angular/core';
import "materialize-css";
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private router: Router,  private activatedRoute: ActivatedRoute) { }
 

  openModal(title, body, icon, butonText=null, url=null){
    
    if(url !=null){
  
      $('#modal1').modal({
        onCloseEnd: function() {
        window.location= url;
         
        } // Callback for Modal close
      }  );
    }
    

    $('#modal-footer').hide();
    $('#modal-title').text(title);
    $('#modal-body').html(body);
    $('#modal-icon').text(icon);
    $('#modal1').modal('open'); 
    if(butonText !=null ){
      $('#modal-footer').show();
      $('#modal-button-footer').text(butonText);
    }
    
    return 0;
  }
  redirectToUrl(url){
    this.router.navigate( [url] );
  }
  
}

