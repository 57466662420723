import { Injectable } from '@angular/core';import { HttpClient, HttpHeaders } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { AnswerLogin } from './../interfaces/answer-login';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private loggedInStatus = JSON.parse(localStorage.getItem('is_login') || 'false');
  constructor( private http: HttpClient) {}
  // login dv --
  login( register: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    // https://angular.io/guide/http#making-a-post-request
    return this.http.post<AnswerLogin>( environment.api_url+'services/loginPartner', register , httpOptions )
          .pipe(
          map( res => {
            if(res.status)
              this.setLoggedIn(true);
            return res;
          }));
  }

  //
  forgot( forgot: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post<AnswerLogin>( environment.api_url+'services/partners/generateToken', forgot , httpOptions ) //generateToken
          .pipe(
          map( res => {
              return res;
          }));
  }

  // partners/changePassword
  changePassword( data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    return this.http.post<AnswerLogin>( environment.api_url+'services/partners/changePassword', data , httpOptions )
          .pipe(
          map( res => {
            return res;
          }));
  }

  setLoggedIn(value: boolean) {
    this.loggedInStatus = value;
    localStorage.setItem('is_login', 'true');
  }
  get isLoggedIn() {
    return this.loggedInStatus
  }


}
