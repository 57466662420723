import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PqrService } from './../../services/pqr.service';
import { ModalService } from './../../services/modal-service.service';
import "materialize-css";
declare var $: any;

@Component({
  selector: 'app-pqr-detail',
  templateUrl: './pqr-detail.component.html'
})
export class PqrDetailComponent implements OnInit {

  comments:any=[];
  ticket:any=[];
  currentPqr:any='';

  newComment:any={
    ticket_id      : '',
    comment_type_id: 3,
    comment        : '',
    file           : null
  };

  constructor(
    private _pqrService: PqrService, 
    private _ModalService: ModalService,
    private router: Router) {
      this.currentPqr = localStorage.getItem('currentPqr');
      this.newComment.ticket_id = this.currentPqr;
      this.getTicket(this.currentPqr);
      this.getCommentsByTicketPartner(this.currentPqr);
      $('#loader').show();
  }

  ngOnInit() {
  }

  getTicket(ticketId) {
    this._pqrService.getTicket(ticketId)
    .subscribe( data => {
      if(data.status){
        this.ticket = data.data;
        setTimeout(() => {
          $('#loader').hide();
        }, 500);
      }
    },
    error => console.error(error));
      $('#loader').hide();  
  }

  getCommentsByTicketPartner(idPqr) {
    this._pqrService.getCommentsByTicketPartner(idPqr)
    .subscribe( data => {
      if(data.status){
        this.comments = data.data;
        setTimeout(() => {
          $('#loader').hide();
        }, 500);
      }
    },
    error => console.error(error));
      $('#loader').hide(); 
  }

  handleFileInput(files: FileList) {
    this.newComment.file = files.item(0);
  }

  saveComment(){
    this._pqrService.saveComment(this.newComment)
    .subscribe( data => {
      if(data.status){
        this._ModalService.openModal('Mensaje', 'Comentario enviado correctamente' , 'check');
        this.router.navigate( ['/secure/pqr'] );
      }
      else{
        this._ModalService.openModal('Mensaje', 'Ocurrió un error enviando el Comentario' , 'close');
      }
    },
    error => console.error(error));
  }
}
