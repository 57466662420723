import { Component, OnInit } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
declare var $: any;

@Component({
  selector: 'app-secured',
  templateUrl: './secured.component.html'
})
export class SecuredComponent implements OnInit {

  name:string;
  isConnected = true;
  constructor(private conectionService: ConnectionService) {
    this.conectionService.monitor().subscribe(isConnected => {
     
      this.isConnected = isConnected;
      if (this.isConnected) {
        $('#offline-cover').hide();
      
      }
      else {
        console.log("offiline");
        $('#offline-cover').show();
      }
    }) 
   }

  ngOnInit() {
    if(navigator.onLine) {
      $('#offline-cover').hide();
     
    }else{
      console.log('showing');
      $('#offline-cover').show();
    }
  }

}
