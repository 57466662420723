import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Answer } from './../interfaces/answer';
import { Ranking } from './../interfaces/ranking';
import { environment } from './../../environments/environment';
import { SecurityService } from './../services/security.service';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  constructor( private http: HttpClient, private _securityService: SecurityService) {}

  getDashboardInfo (partnerId:string) {
    let params = new HttpParams().set("partner_id",partnerId);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let data = this.http.get<Answer>( environment.api_url+'services/partners/getDashboardInfo', {headers: httpOptions.headers, params: params} );
    this._securityService.processAnswer(data);
    return data;
  }

  getTotalRedemptionPartner (partnerId:string) {
    let params = new HttpParams().set("partner_id",partnerId);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let data = this.http.get<Answer>( environment.api_url+'services/partners/getTotalRedemptionPartner', {headers: httpOptions.headers, params: params} );
    this._securityService.processAnswer(data);
    return data;
  }


}
