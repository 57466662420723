import { Component, OnInit } from '@angular/core';
import { LoginService } from './../../services/login.service';
import { environment } from './../../../environments/environment';
import { ModalService } from './../../services/modal-service.service';
declare var $: any;

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html'
})
export class ForgotComponent implements OnInit {

  forgotData:any ={
    email:'',
    company_id:environment.company_id
  }

  constructor(private _LoginService: LoginService,
              private _ModalService: ModalService) {}

  ngOnInit() {
    $(document).ready(function(){
      $('.modal').modal();
    });
  }

  forgot(form){
    this._LoginService.forgot(this.forgotData)
      .subscribe( data => {
          form.resetForm();
          this._ModalService.openModal('Información', 'Si la dirección que nos acabas de proporcionar coincide con alguna de nuestras cuentas, te enviaremos un correo electrónico con instrucciones para restablecer tu contraseña. En caso de que no lo recibas, contacta con nosotros' , 'info');
          this.forgotData.email="";
      },
      error => console.error(error));
  }

}
