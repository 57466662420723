import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html'
})
export class LanguageComponent implements OnInit {

  constructor( private translate: TranslateService ) {
    let lang = (localStorage.getItem('lang')==null || localStorage.getItem('lang')=='null') ? 'es': localStorage.getItem('lang');
    translate.setDefaultLang(lang);
    translate.use(lang);
  }
  
  ngOnInit() {
  }

  //Change language to Spanish dv --
  changeLanguageToSpanish(): void {
    console.log('settign spanis');
    this.translate.use('es');
    localStorage.setItem('lang', 'es');
  }

  //Change language to English dv --
  changeLanguageToEnglish(): void {
    console.log('settign english');
    this.translate.use('en');
    localStorage.setItem('lang', 'en');
  }

}
