// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  api_url:'https://sgc-qa.yadinero.co/api/',
  chatbot_url:'https://sgc-qa.yadinero.co/chatbot/',
  pqr_url:'https://pqr-qa.yadinero.co/api/services/',
  sip_url:'https://puntos-qa.yadinero.co/api/',

  landing_sip:'http://localhost:4200/#/referred/',

  defaul_img_points:"https://via.placeholder.com/200x150",
  company_id:1,
  credit_type:6,
  front_url: 'http://localhost',

  facebook_url: 'https://www.facebook.com/yadinero',
  instagram_url: 'https://www.instagram.com/yadinero.co',
  whatsapp: 'https://wa.me/573128956522',
  mail: 'http://localhost/contacto',
  lang_url:'https://sgc-qa.yadinero.co/language/1/'

};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
