
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import {AuthGuard } from './auth.guard';
// login dv --
import { LoginComponent } from './components/login/login.component';
import { ForgotComponent } from './components/forgot/forgot.component';
// auth dv --
import { AuthComponent } from './components/auth/auth.component';
// home dasboard dv -- 
import { DashboardComponent } from './components/dashboard/dashboard.component';
// profile 
import { ProfileComponent } from './components/profile/profile.component';
// Pqr dv --
import { PqrComponent } from './components/pqr/pqr.component';
import { PqrListComponent } from './components/pqr-list/pqr-list.component';
import { PqrDetailComponent } from './components/pqr-detail/pqr-detail.component';
// setting dv --
import { SettingsComponent } from './components/settings/settings.component';
// new referral dv -
import { NewReferralComponent } from './components/new-referral/new-referral.component';
import { SimulatorComponent } from './components/simulator/simulator.component';
// Recommended dv --
import { RecommendedComponent } from './components/recommended/recommended.component';
import { RecommendedHistoryComponent } from './components/recommended-history/recommended-history.component';
// Wallet dv --
import { WalletComponent } from './components/wallet/wallet.component';
// secured dv --
import { SecuredComponent } from './components/secured/secured.component';
// lottery dv --
import { LotteryComponent } from './components/lottery/lottery.component';

const appRoutes: Routes = [
  //Site routes goes here
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  {
      path: 'secure',
      component: SecuredComponent,
      children: [
        { path: 'home', component: DashboardComponent, canActivate:[AuthGuard] },
        { path: 'profile', component: ProfileComponent, canActivate:[AuthGuard] },
        { path: 'pqr', component: PqrListComponent, canActivate:[AuthGuard] },
        { path: 'pqr-add', component: PqrComponent, canActivate:[AuthGuard] },
        { path: 'pqr-detail', component: PqrDetailComponent, canActivate:[AuthGuard] },
        { path: 'settings', component: SettingsComponent, canActivate:[AuthGuard] },
        { path: 'new-referral', component: NewReferralComponent, canActivate:[AuthGuard] },
        { path: 'simulator', component: SimulatorComponent, canActivate:[AuthGuard] },
        { path: 'recommended', component: RecommendedComponent, canActivate:[AuthGuard] },
        { path: 'history', component: RecommendedHistoryComponent, canActivate:[AuthGuard] },
        { path: 'wallet', component: WalletComponent, canActivate:[AuthGuard] }, 
        { path: 'lottery', component: LotteryComponent, canActivate:[AuthGuard] }, 
      ]
  },

  {
    path: '',
    component: AuthComponent,
    children: [
      { path: '', component: LoginComponent },
      { path: 'login', component: LoginComponent },
      { path: 'forgot', component: ForgotComponent},
      { path: 'login/:source', component: LoginComponent},
    ]
},
  // otherwise redirect to home

];

export const APP_ROUTING = RouterModule.forRoot(appRoutes, {useHash:true});
