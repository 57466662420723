import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  date: any;
  front_url: string;
  about_as: string = 'quienes-somos';
  faq: string = 'preguntas-frecuentes';
  p2p: string = 'place-to-pay';
  how_request: string = 'manejo-de-tu-prestamo';
  pqrs: string = 'pqr';
  secure: string = 'seguro-yadinero';
  benefits: string = 'beneficios';
  payments: string = 'pagos-en-linea';
  contact: string = 'contacto';


  constructor() {
    this.front_url = environment.front_url;
    this.date = new Date();
  }

  ngOnInit() {
  }

}
