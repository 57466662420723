import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Answer } from '../interfaces/answer';
import { SecurityService } from './security.service';

@Injectable({
  providedIn: 'root'
})
export class referredService {

  constructor(private http: HttpClient,  private _securityService: SecurityService) { }

  // create recommended dv --
  createRecommended( data: any) {
    return this.http.post<Answer>(  environment.api_url+'services/partners/createRecommended', data , this.getServiceToken( 'post' ))
          .pipe(
            map( res => {
              return res;
          }));
  }

  // create pre-requests dv --
  createPreRequest( data: any) {
    return this.http.post<Answer>(  environment.api_url+'services/partners/createPreRequest', data , this.getServiceToken( 'post' ))
          .pipe(
            map( res => {
              return res;
          }));
  }
  
  // update partner dv --
  updatePreRequest( data: any) {
    return this.http.post<Answer>(  environment.api_url+'services/partners/updatePreRequest', data , this.getServiceToken( 'post' ))
          .pipe(
            map( res => {
              return res;
          }));
  }

  // create pre-requests dv --
  sendMessageDataPolicy( data: any) {
    return this.http.post<Answer>(  environment.api_url+'services/partners/sendMessageDataPolicy', data , this.getServiceToken( 'post' ))
          .pipe(
            map( res => {
              return res;
          }));
  }

  // create pre-requests dv --
  verifyMessageDataPolicy( data: any) {
    return this.http.post<Answer>(  environment.api_url+'services/partners/verifyMessageDataPolicy', data , this.getServiceToken( 'post' ))
          .pipe(
            map( res => {
              return res;
          }));
  }
  
  // update partner dv --
  getPartnerCustomer( data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams()
                    .set("partner_id", data.partner_id)
                    .set("doc_num", data.doc_num);
    return this.http.get<Answer>(  environment.api_url+'services/partners/getPartnerCustomer', {headers: httpOptions.headers, params: params })
          .pipe(
            map( res => {
              return res;
          }));
  }

  // header get dv --
  getServiceToken( method:string = 'get' )
  {
    if( method == 'get' )
    {
      return { headers: new HttpHeaders({ 'Content-Type':  'application/json' }) };
    }
    return { headers: new HttpHeaders({ 'Content-Type':  'application/json', 'Authorization':  localStorage.getItem('api_token') }) };
  }

}
