import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';

declare var $: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  name:string;
  lastname:string;
  front_url: string;

  constructor( private router:Router ) { 
    this.front_url = environment.front_url 
  }

  ngOnInit() {

    $(document).ready(function(){
      $(".dropdown-trigger").dropdown();
    });

    this.name =  localStorage.getItem('name');
    this.lastname =  localStorage.getItem('lastname');

  }

  logOut(){
    let lang=localStorage.getItem('lang');
    localStorage.clear();
    localStorage.setItem('lang', lang);
    this.router.navigate( ['/login'] );
  }


}
