import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Recommended } from './../interfaces/recommended';
import { SecurityService } from './../services/security.service';

@Injectable({
  providedIn: 'root'
})

export class RecommendedService {

  constructor(private http: HttpClient,  private _securityService: SecurityService) { }

  getPreRequestPartner(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams()
                    .set("partner_id", data.partner_id)
                    .set("filter", data.filter)
                    .set("status", data.status);
    return this.http.get<Recommended>( environment.api_url+'services/partners/getPreRequestPartner', {headers: httpOptions.headers, params: params } );
  }

  getPreRequestPartnerPage(data: any, page) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams()
                    .set("partner_id", data.partner_id)
                    .set("filter", data.filter)
                    .set("status", data.status);
    return this.http.get<Recommended>( environment.api_url+'services/partners/getPreRequestPartner?pre_requests_list='+page, {headers: httpOptions.headers, params: params } );
  }

  getPreRequestHistory(data: any){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams()
                    .set("partner_id", data.partner_id)
                    .set("pre_request_id", data.id);
    return this.http.get<Recommended>( environment.api_url+'services/partners/getPreRequestHistory', {headers: httpOptions.headers, params: params } );
  }

  getPreRequestStatus(partnerId:string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':  localStorage.getItem('api_token')
      })
    };
    let params = new HttpParams().set("partner_id",partnerId);
    return this.http.get<Recommended>( environment.api_url+'services/partners/getPreRequestStatus', {headers: httpOptions.headers, params: params } );
  }

}
