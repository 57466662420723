import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import { ConnectionService } from 'ng-connection-service';

declare var $: any;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html'
})


export class AuthComponent implements OnInit {

  front_url: string;
  simulate: string = 'simular-credito';
  status = 'ONLINE';
  isConnected = true;
  constructor(private conectionService: ConnectionService) { 
    this.front_url = environment.front_url;
    this.conectionService.monitor().subscribe(isConnected => {
     
      this.isConnected = isConnected;
      if (this.isConnected) {
        $('#offline-cover').hide();
      
      }
      else {
        console.log("offiline");
        $('#offline-cover').show();
      }
    }) 
  }

  ngOnInit()
  {
    $(document).ready(function(){
      $(".dropdown-trigger").dropdown();
    });
   
    if(navigator.onLine) {
      $('#offline-cover').hide();
     
    }else{
      console.log('showing');
      $('#offline-cover').show();
    }

  }

}
