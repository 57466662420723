import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Ng5SliderModule } from 'ng5-slider';
import { environment } from './../environments/environment';
import { RedirectPipe } from './pipes/redirect.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { AuthGuard } from './auth.guard';
import { PaginationModule } from 'ngx-bootstrap/pagination';
// Rutas
import { APP_ROUTING } from './app.routes';
// Language component
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageComponent } from './components/language/language.component';

//charts module  dv--
import { ChartsModule } from 'ng2-charts';
// progres bar dv --
import {ProgressBarModule} from "angular-progress-bar"
// login dv --
import { LoginComponent } from './components/login/login.component';
import { LoginService } from './services/login.service';
// forgot dv --
import { ForgotComponent } from './components/forgot/forgot.component';
// home dv --
import { DashboardComponent } from './components/dashboard/dashboard.component';
// pqrs dv --
import { PqrComponent } from './components/pqr/pqr.component';
import { PqrListComponent } from './components/pqr-list/pqr-list.component';
import { PqrDetailComponent } from './components/pqr-detail/pqr-detail.component';
//profile dv --
import { ProfileComponent } from './components/profile/profile.component';
// settings dv --
import { SettingsComponent } from './components/settings/settings.component';
// new referral dv -
import { NewReferralComponent } from './components/new-referral/new-referral.component';
import { SimulatorComponent } from './components/simulator/simulator.component';
// recommended dv --
import { RecommendedComponent } from './components/recommended/recommended.component';
import { RecommendedHistoryComponent } from './components/recommended-history/recommended-history.component';
import { RecommendedService } from './services/recommended.service';
// recommended dv --
import { WalletComponent } from './components/wallet/wallet.component';
import { WalletService } from './services/wallet.service';
// lottery dv --
import { LotteryComponent } from './components/lottery/lottery.component';

// Components  dv --
import { AuthComponent } from './components/auth/auth.component';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { MainMenuComponent } from './components/shared/main-menu/main-menu.component';
import { MainBannerComponent } from './components/shared/main-banner/main-banner.component';
import { MainMenuButtonsComponent } from './components/shared/main-menu-buttons/main-menu-buttons.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SideMenuComponent } from './components/shared/side-menu/side-menu.component';
import { SubBannerComponent } from './components/shared/sub-banner/sub-banner.component';
import { SecuredComponent } from './components/secured/secured.component';
import { FloatingButtonsComponent } from './components/shared/floating-buttons/floating-buttons.component';
import { RatingModule } from 'ng-starrating';
import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angular-6-social-login";

import { PointsContractComponent } from './components/shared/points-contract/points-contract.component';
import { TermConditionsComponent } from './components/shared/term-conditions/term-conditions.component';

export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
      [
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider("251883692383850")
        }
     ]);
  return config;
}

@NgModule({
  declarations: [
    LoginComponent,
    ForgotComponent,
    AppComponent,
    AuthComponent,
    RedirectPipe,
    SearchPipe,
    NavbarComponent,
    MenuComponent,
    DashboardComponent,
    ProfileComponent,
    PqrComponent,
    PqrListComponent,
    PqrDetailComponent,
    SettingsComponent,
    NewReferralComponent,
    SimulatorComponent,
    RecommendedComponent,
    RecommendedHistoryComponent,
    WalletComponent,
    SecuredComponent,
    LotteryComponent,

    LanguageComponent,
    SubBannerComponent,
    FloatingButtonsComponent,
    FooterComponent,
    MainBannerComponent,
    MainMenuButtonsComponent,
    MainMenuComponent,
    SideMenuComponent,


    PointsContractComponent,
    TermConditionsComponent,
  ],

  imports: [
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    APP_ROUTING,
    Ng5SliderModule,
    SocialLoginModule,
    ReactiveFormsModule,
    RatingModule,
    HttpClientModule,
    TranslateModule.forRoot(),
    TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [ HttpClient ]
          }
        }),
    ChartsModule,
    ProgressBarModule,
    PaginationModule.forRoot()
  ],

  providers: [
    RecommendedService,
    LoginService,
    WalletService,
    AuthGuard,
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// json string dv--
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
